


























































import {Component, Vue} from 'vue-property-decorator'
import GenelToplam from "@/components/kasa/GenelToplam.vue";
import KarZarar from "@/components/kasa/KarZarar.vue";
import AylikGelir from "@/components/kasa/AylikGelir.vue";
import AylikGider from "@/components/kasa/AylikGider.vue";

@Component({
    components: {AylikGider, AylikGelir, KarZarar, GenelToplam},
})

export default class KasaDetay extends Vue {

}
