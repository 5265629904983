









import {Component, Vue} from 'vue-property-decorator'
import KasaDetay from "@/components/kasa/KasaDetay.vue";

@Component({
    components: {
        KasaDetay
    },
})
export default class Kasa extends  Vue {

}
