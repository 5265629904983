<script>
    import { Line } from "vue-chartjs";

    export default {
        extends: Line,
        mounted() {
            this.renderChart(
                {
                    labels: [
                        "Kasım",
                        "Aralık",
                        "Ocak",
                        "Şubat",
                        "Mart",
                        "Nisan",
                        "Mayıs",
                        "Haziran",
                        "Temmuz",
                        "Ağustos",
                        "Eylül",
                        "Ekim",
                    ],
                    datasets: [
                        {
                            label: "Aylık Gelir",
                            data: [781000, 800000,950000, 1150000, 980000, 440000, 378000,200000,191250,225000,290000,314500],
                            backgroundColor: "transparent",
                            borderColor: "purple",
                            pointBackgroundColor: "white",

                        },
                    ]
                },
                {
                    responsive: true,
                    maintainAspectRatio: false,
                    title: {
                        display: false,
                        text: "AYLIK GELİR",
                        fontColor:"Blue"
                    }
                }
            );
        }
    };
</script>